@import '@aurora/shared-client/styles/mixins/_focus.pcss';

.lia-placeholder {
  &-wrapper,
  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-wrapper {
    height: 56px;
    border: 1px dashed var(--lia-bs-gray-500);
    border-radius: var(--lia-bs-border-radius);
  }

  &-btn {
    line-height: normal;

    @include default-focus-outline();
  }

  &-span {
    font-size: var(--lia-bs-font-size-sm);
    font-weight: var(--lia-bs-font-weight-base);
  }
}
